<template>
  <InputCheckboxGroupChips :name="'activities'" :options="options" />
</template>

<script>
import {} from "vue";

export default {
  setup() {
    const options = [
      {
        label: "edukacją",
        value: "education",
      },
      {
        label: "kreatywnośćą",
        value: "creativity",
      },
      {
        label: "muzyką",
        value: "music",
      },
      {
        label: "grami i zabawami",
        value: "games",
      },
      {
        label: "pomocą w odrabianiu lekcji",
        value: "homework",
      },
    ];
    return {
      options,
    };
  },
};
</script>

<style scoped></style>
