<template>
  <div class="content">
    <OfferNannyInputResponsibilities
      v-model:child-care="offerNanny.requirements.responsibilities.careChild"
      v-model:activities="offerNanny.requirements.responsibilities.activities"
      v-model:home-care="offerNanny.requirements.responsibilities.careHome"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      :to="{ name: 'OfferNannyCreateDetails' }"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { useOfferNannyStore } from "@/stores/offerNanny";
import { ref } from "@vue/reactivity";

import OfferNannyInputResponsibilities from "@/components/Offer/Nanny/OfferNannyInputResponsibilities.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    OfferNannyInputResponsibilities,
    ButtonPrimaryNext,
  },

  setup() {
    const offerNanny = useOfferNannyStore();

    const isValid = ref(false);

    return {
      offerNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}

@media (max-width: 1200px) {
}
</style>
