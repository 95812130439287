<template>
  <div class="container">
    <BaseH1 :html="'Zaznacz,<br />co jest skutecznym dla niani'" class="h1" />

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Opieka nad dzieckiem'" />
      <InputCheckboxGroupChipsChildCare
        v-model="offer.responsibilities.childCare"
      />
    </div>

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Zajęcia'" />
      <InputCheckboxGroupChipsActivities
        v-model="offer.responsibilities.activities"
      />
    </div>

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Pomoc domowa'" />
      <InputCheckboxGroupRound
        v-model="offer.responsibilities.homeCare"
        :name="'home-care'"
        :options="optionsHomeCare"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import InputCheckboxGroupChipsChildCare from "@/components/UI/InputCheckboxGroupChipsChildCare.vue";
import InputCheckboxGroupChipsActivities from "@/components/UI/InputCheckboxGroupChipsActivities.vue";
import InputCheckboxGroupRound from "@/components/UI/Base/InputCheckboxGroupRound.vue";

export default {
  components: {
    BaseH1,
    BaseH2,
    InputCheckboxGroupChipsChildCare,
    InputCheckboxGroupChipsActivities,
    InputCheckboxGroupRound,
  },

  props: {
    childCare: Array,
    activities: Array,
    homeCare: Array,
  },

  emits: [
    "update:childCare",
    "update:activities",
    "update:homeCare",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const offer = reactive({
      responsibilities: {
        childCare: props.childCare,
        activities: props.activities,
        homeCare: props.homeCare,
      },
    });

    // Way out binding
    watch(
      () => offer.responsibilities.childCare,
      (newChildCare) => emit("update:childCare", newChildCare),
      { deep: true }
    );
    watch(
      () => offer.responsibilities.activities,
      (newActivities) => emit("update:activities", newActivities),
      { deep: true }
    );
    watch(
      () => offer.responsibilities.homeCare,
      (newHomeCare) => emit("update:homeCare", newHomeCare),
      { deep: true }
    );

    // Way in binding
    watch(
      () => props.childCare,
      (newChildCare) => (offer.responsibilities.childCare = newChildCare),
      { deep: true }
    );
    watch(
      () => props.activities,
      (newActivities) => (offer.responsibilities.activities = newActivities),
      { deep: true }
    );
    watch(
      () => props.homeCare,
      (newHomeCare) => (offer.responsibilities.homeCare = newHomeCare),
      { deep: true }
    );

    const isValid = computed(() => {
      return Boolean(offer.responsibilities.childCare.length);
    });
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const { DICTIONARY } = useApplicationNannyDictionary();
    const optionsHomeCare = Object.values(DICTIONARY.responsibilities.homeCare);

    return {
      offer,
      isValid,
      optionsHomeCare,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.h1 {
  width: 560px;
}
.wrapper-checkboxes {
  width: 560px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  row-gap: 15px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .h1 {
    width: 100%;
  }
  .wrapper-checkboxes {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    row-gap: 15px;
  }
}
</style>
