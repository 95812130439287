<template>
  <InputCheckboxGroupChips :name="'child-care'" :options="options" />
</template>

<script>
export default {
  setup() {
    const options = [
      {
        label: "higiena",
        value: "hygiene",
      },
      {
        label: "karmenie",
        value: "feeding",
      },
      {
        label: "spacery",
        value: "walks",
      },
      {
        label: "towarzyszenie i zabezpieczenie",
        value: "safeguarding",
      },
    ];
    return {
      options,
    };
  },
};
</script>

<style scoped></style>
